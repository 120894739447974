<template>
  <div class="container booking-request-container">
    <div class="container">
      <div class="card theme-card border-lg-0">
        <alert v-if="loading" class="light-shadow m-4" />
        <alert class="m-4" :hideLoader="true" v-else-if="!bookingRequest">
          Booking request not found.
        </alert>
        <div
          class="
            card
            mb-0
            theme-card
            mobile-tablet-no-card
            p-4
            table-container
            border-lg-0
          "
          v-else
        >
          <div
            class="
              col-12
              p-0
              mb-5
              d-flex
              flex-md-row flex-column
              justify-content-between
              align-items-center
            "
          >
            <h1>Booking Request Details</h1>
            <CopyBookingPortalUrl />
          </div>

          <input-control
            v-model="bookingRequest.user.name"
            type="horizontal"
            labelClass="col-md-3"
            inputClass="disabled"
            >Client</input-control
          >
          <input-control
            v-model="bookingRequest.treatment.service.name"
            type="horizontal"
            labelClass="col-md-3"
            inputClass="disabled"
            >Service</input-control
          >
          <div class="form-group row">
            <label class="col-md-2 col-form-label col-md-3">Start Time</label>
            <div class="col">
              <div class="form-control">
                {{ dateFormat(bookingRequest.start_time) }}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-2 col-form-label col-md-3">End Time</label>
            <div class="col">
              <div class="form-control">
                {{ dateFormat(bookingRequest.end_time) }}
              </div>
            </div>
          </div>
          <input-control
            v-model="bookingRequest.additional_notes"
            control="textarea"
            type="horizontal"
            labelClass="col-md-3"
            inputClass="disabled"
            >Additional Notes</input-control
          >
          <div class="form-group row">
            <label class="col-md-2 col-form-label col-md-3">Referred By</label>
            <div class="col">
              <div class="form-control">
                {{
                  bookingRequest.referred_by_type
                    ? `${textToUpperCase(bookingRequest.referred_by_type)} / ${
                        bookingRequest.referred_by_name
                      }`
                    : "N/A"
                }}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-2 col-form-label col-md-3">Status</label>
            <div class="col">
              <span
                class="font-weight-bold p-2"
                :class="
                  {
                    pending: 'badge badge-pill badge-warning',
                    accepted: 'badge badge-pill badge-deep-success',
                    rejected: 'badge badge-pill badge-danger',
                    null: 'badge badge-pill badge-secondary',
                  }[bookingRequest.status]
                "
              >
                {{ textToUpperCase(bookingRequest.status) }}
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-2 col-form-label col-md-3"
              >Payment Method</label
            >
            <div class="col">
              <div class="form-control">
                {{ getPaymentMethod(bookingRequest) || "N/A" }}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-2 col-form-label col-md-3"
              >Payment Method</label
            >
            <div class="col">
              <span
                class="font-weight-bold p-2"
                :class="
                  {
                    unpaid: 'badge badge-pill badge-warning',
                    paid: 'badge badge-pill badge-deep-success',
                    failed: 'badge badge-pill badge-danger',
                    null: 'badge badge-pill badge-secondary',
                  }[bookingRequest.payment_status]
                "
              >
                {{ textToUpperCase(bookingRequest.payment_status) }}
              </span>
            </div>
          </div>
          <div class="d-flex justify-content-center flex-wrap">
            <button
              v-if="showActionsButton(bookingRequest)"
              class="btn btn-black mx-2 mt-2 col-12 col-md-auto"
              title="Accept Booking Request"
              @click="acceptBookingRequestHelper(bookingRequest)"
            >
              Accept Request <icon class="ml-2" type="calendar-check fa-lg" />
            </button>
            <button
              v-if="showActionsButton(bookingRequest)"
              class="btn btn-danger mx-2 mt-2 col-12 col-md-auto"
              title="Reject Booking Request"
              @click="rejectBookingRequestHelper(bookingRequest)"
            >
              Reject Request <icon class="ml-2" type="calendar-xmark fa-lg" />
            </button>
            <a
              v-if="!isPatient"
              class="mx-2 mt-2 btn btn-theme col-12 col-md-auto"
              :href="`/clients/${bookingRequest.user.profile_id}/intake-forms/create`"
              target="_blank"
            >
              Create New Intake Form
            </a>
            <button
              v-if="showPayButton(bookingRequest)"
              class="btn btn-deep-success mx-2 mt-2 col-12 col-md-auto"
              :title="
                bookingRequest.payment_method === 'secure_payment_link'
                  ? 'Send Payment Link'
                  : 'Pay Appointment'
              "
              @click="sendPaymentEventRequest(bookingRequest)"
            >
              {{
                bookingRequest.payment_method === "secure_payment_link"
                  ? "Send Payment Link"
                  : "Pay Appointment"
              }}
              <i
                class="ml-2 fas fa-spin fa-circle-notch"
                v-if="payAppointmentLoading == bookingRequest.id"
              ></i>
              <icon v-else class="ml-2" type="credit-card fa-lg" />
            </button>
          </div>
        </div>
      </div>
      <accept-booking-request-modal
        v-if="triggeredBookingRequest"
        :booking-request="triggeredBookingRequest"
        @finish="updateBookingStatus('accepted')"
      />
      <reject-booking-request-modal
        v-if="triggeredBookingRequest"
        :booking-request="triggeredBookingRequest"
        @finish="updateBookingStatus('rejected')"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CopyBookingPortalUrl from "@/components/booking/CopyBookingPortalUrl.vue";
import AcceptBookingRequestModal from "@/components/booking/AcceptBookingRequestModal.vue";
import RejectBookingRequestModal from "@/components/booking/RejectBookingRequestModal.vue";
import dayjs from "dayjs";
import helpers from "@/utils/helpers";
import Swal from "sweetalert2";

export default {
  components: {
    CopyBookingPortalUrl,
    AcceptBookingRequestModal,
    RejectBookingRequestModal,
  },
  name: "booking-requests.view",
  data() {
    return {
      loading: false,
      triggeredBookingRequest: null,
      payAppointmentLoading: null,
      bookingRequest: null,
    };
  },
  computed: {
    ...mapState({
      isPatient: (state) => state.auth.user?.isPatient,
    }),
  },
  watch: {
    "$route.params.id": {
      immediate: true, // Run on component load
      handler(newId) {
        this.initPage(newId);
      },
    },
  },
  methods: {
    ...mapActions({
      getBookingRequest: "booking/getBookingRequestById",
    }),
    initPage(id) {
      this.loading = true;
      this.getBookingRequest(id).then((data) => {
        this.loading = false;
        if (data) {
          this.bookingRequest = data;
        }
      });
    },
    dateFormat: function (date) {
      return dayjs(date).utc().format("MMM D, YYYY [at] h:mm a");
    },
    textToUpperCase: function (status) {
      return helpers.textToUpperCase(status);
    },
    showActionsButton: function (bookingRequest) {
      if (this.isPatient) {
        return false;
      }
      if (this.isProvider) {
        return (
          bookingRequest.status === "pending" &&
          this.onlineBookingSettings?.providers_can_perform_actions
        );
      }
      return bookingRequest.status === "pending";
    },
    updateBookingStatus: function (status) {
      this.bookingRequest.status = status;
      Swal.fire({
        title: "Success",
        text: `Booking request has been ${status} successfully`,
        icon: "success",
      });
    },
    getPaymentMethod: function (bookingRequest) {
      switch (bookingRequest.payment_method) {
        case "credit_card":
          return "Card";
        case "secure_payment_link":
          return "Payment Link";
        case "other":
          return "Other";
        default:
          return "N/A";
      }
    },
    showPayButton: function (bookingRequest) {
      return (
        bookingRequest.payment_status !== "paid" &&
        bookingRequest.status === "accepted" &&
        bookingRequest.payment_method !== "other" &&
        bookingRequest.appointment_status === "Verified"
      );
    },
    acceptBookingRequestHelper: function (bookingRequest) {
      this.triggeredBookingRequest = bookingRequest;
      this.$nextTick(() => {
        this.$modal.show("acceptBookingRequestModal");
      });
    },
    rejectBookingRequestHelper: function (bookingRequest) {
      this.triggeredBookingRequest = bookingRequest;
      this.$nextTick(() => {
        this.$modal.show("rejectBookingRequestModal");
      });
    },
    sendPaymentEventRequest: async function (bookingRequest) {
      const vm = this;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      let confirmButtonText = "Yes, pay!";
      if (bookingRequest.payment_method === "secure_payment_link") {
        confirmButtonText = "Yes, send payment link!";
      }
      return swalWithBootstrapButtons
        .fire({
          title: "Payment Details",
          html: `<div class="text-center">
                    <div class="d-flex justify-content-between mx-3">
                        <span><b>Amount:</b></span>
                        <span>$${`${+parseFloat(
                          bookingRequest.cost?.amount || 0
                        ).toFixed(2)}`.replace(
                          /(\d)(?=(\d{3})+(?!\d))/g,
                          "$1,"
                        )}</span>
                    </div>
                    <div class="d-flex justify-content-between mx-3">
                        <span><b>Expenses:</b></span>
                        <span>$${`${+parseFloat(
                          bookingRequest.cost?.expenses || 0
                        ).toFixed(2)}`.replace(
                          /(\d)(?=(\d{3})+(?!\d))/g,
                          "$1,"
                        )}</span>
                    </div>
                     <div class="d-flex justify-content-between mx-3">
                        <span><b>Sub Total <small class="text-muted">(Inc. expenses)</small>:</b></span>
                          <span>$${`${+parseFloat(
                            bookingRequest.cost?.sub_total || 0
                          ).toFixed(2)}`.replace(
                            /(\d)(?=(\d{3})+(?!\d))/g,
                            "$1,"
                          )}</span>
                    </div>
                    <div class="d-flex justify-content-between mx-3">
                        <span><b>Interest:</b></span>
                        <span>$${`${+parseFloat(
                          bookingRequest.cost?.interest || 0
                        ).toFixed(2)}`.replace(
                          /(\d)(?=(\d{3})+(?!\d))/g,
                          "$1,"
                        )}</span>
                    </div>
                    <div class="d-flex justify-content-between mx-3">
                        <span><b>Tax:</b></span>
                        <span>$${`${+parseFloat(
                          bookingRequest.cost?.tax || 0
                        ).toFixed(2)}`.replace(
                          /(\d)(?=(\d{3})+(?!\d))/g,
                          "$1,"
                        )}</span>
                    </div>
                    <hr/>
                    <div class="d-flex justify-content-between mt-3 mx-3">
                        <span><b>Total:</b></span>
                          <span>$${`${+parseFloat(
                            bookingRequest.cost?.total || 0
                          ).toFixed(2)}`.replace(
                            /(\d)(?=(\d{3})+(?!\d))/g,
                            "$1,"
                          )}</span>
                    </div>
                   </div>`,
          icon: "",
          showCancelButton: true,
          confirmButtonText,
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              vm.payAppointmentLoading = bookingRequest.id;
              const res = await vm.$http.post(
                `appointments/${bookingRequest.appointment_id}/pay`
              );
              if (res) {
                vm.getBookingRequestsHelper(vm.page);
                Swal.fire({
                  title: "Success",
                  text: res.data?.message || "Payment successful.",
                  icon: "success",
                });
              }
            } catch (err) {
              Swal.fire("Error", err.data.error.message, "error");
            } finally {
              vm.payAppointmentLoading = null;
            }
          }
        });
    },
  },
};
</script>

<style lang="scss">
.booking-request-container {
  .disabled {
    input,
    textarea {
      cursor: default;
      pointer-events: none;
    }
  }
}
</style>
